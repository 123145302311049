.about-section {
  align-self: stretch;
  margin-top: 154px;
}

@media (max-width: 991px) {
  .about-section {
    max-width: 100%;
    margin-top: 40px;
  }
}

.about-content {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .about-content {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}

.about-image-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  height: 600px;
  width: 50%;
  margin-left: 0px;
}

@media (max-width: 991px) {
  .about-image-column {
    width: 70%;

    display: flexbox;
    align-items: center;

    object-position: center;


    align-self: center;

  }
}

.about-image {
  aspect-ratio: 0.98;
  object-fit: auto;
  object-position: center;
  width: 100%;
  height: 400px;
  flex-grow: 1;
  max-width: 100%;
  border-radius: 29px;
  background: linear-gradient(320deg, #232732 14.78%, #232732 95.73%);
  box-shadow: -15px -15px 20px 0 rgba(255, 255, 255, 0.25),
    15px 15px 20px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 1.1;
  /* width: 45%; */
  /* padding: 10px 10px 10px; */
  justify-self: right;
  align-self: right;
}

@media (max-width: 1000px) {
  .about-image {
    
    object-fit: auto;
    object-position: center;
    
    align-self: stretch;
    margin-top: 40px;
    
    align-self: center;
    align-items: center;
    justify-self: center;
    justify-content: center;
  }
}

.about-text-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  align-items: center;
}

@media (max-width: 991px) {
  .about-text-column {
    width: 100%;
  }
}

.about-text-content {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #fff;
  letter-spacing: 0.38px;
  margin: auto 0;
  
  align-self: center;
}

@media (max-width: 991px) {
  .about-text-content {
    max-width: 70%;
    margin-top: 40px;
  }
}

.about-title {
  text-align: center;
  font: 500 34px/71% Poppins, -apple-system, Roboto, Helvetica,
    sans-serif;
  font-weight: 600;
}

@media (max-width: 991px) {
  .about-title {
    max-width: 100%;
  }
}

.about-description {
  margin-top: 37px;
  font: 300 21px/28px Poppins, -apple-system, Roboto, Helvetica,
    sans-serif;
    
  text-align: justify;
}

@media (max-width: 991px) {
  .about-description {
    max-width: 100%;
  }
}

.section-title {
  font-family: Poppins, sans-serif;
  font-size: 34px;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.38px;
  line-height: 71%;
}

@media (max-width: 991px) {
  .section-title {
    max-width: 100%;
    white-space: initial;
  }
}

.projects-section {
  align-self: stretch;
  display: flex;
  margin-top: 217px;
  flex-direction: column;
  padding: 0 57px;
}

@media (max-width: 991px) {
  .projects-section {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
}

.skills-section {
  align-self: end;
  margin-top: 1098px;
}

@media (max-width: 991px) {
  .skills-section {
    margin-top: 40px;
  }
}

.certifications-section {
  align-self: stretch;
  display: flex;
  margin-top: 928px;
  flex-direction: column;
  padding: 0 56px;
}

@media (max-width: 991px) {
  .certifications-section {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
}

.experience-section {
  align-self: end;
  margin-top: 585px;
}

@media (max-width: 991px) {
  .experience-section {
    margin-top: 40px;
  }
}

.contact-section {
  color: #fff;
  text-align: center;
  letter-spacing: 0.38px;
  align-self: start;
  margin: 1351px 0 0 56px;
  font: 500 34px/71% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .contact-section {
    margin: 40px 0 0 10px;
  }
}