.nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-item a {
  font-family: Poppins, sans-serif;
  font: 400 20px/71% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  color: #ffffff;

  font-weight: 700;

  background-color: #24272c;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 30px;
  border-radius: 24px;
  border: none;
  box-shadow: -7px -7px 10px 0px rgba(255, 255, 255, 0.25),
    10px 10px 12px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease;
}

.nav-link {
  font-family: Poppins, sans-serif;
  border-radius: 24px;
  box-shadow: -5px -5px 10px 0px rgba(255, 255, 255, 0.25),
    5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: #24272c;
  justify-content: center;
  padding: 23px 29px;
  color: #fff;
  text-decoration: none;
}

.nav-link:hover {
  color: #ffffff;
  background-color: var(--theam);
  box-shadow: -3px -3px 5px 0px rgba(255, 255, 255, 0.25),
    5px 5px 6px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 991px) {
  .nav-link {
    white-space: initial;
    padding: 0 20px;
  }
}

.navbar-links {
  align-self: stretch;
  display: flex;
  gap: 20px;
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.38px;
  line-height: 109%;
  justify-content: space-between;
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: var(--bg);
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 4 rgba(0,0,0,0.2);
}

.navbar-menu_container p {
  margin: 1rem  0;
  

}

@media screen and (max-width: 1000px) {
  .navbar-links {
      display: none;
  }

  .navbar-menu {
      display: flex;
  }
}