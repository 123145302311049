.tabs-container {
    width: 100%;
    margin: 20px auto;
}

.element-container {
    padding: 50px 30px 0 30px;
}
 
.tabs {
    display: flex;
    text-align: center;
    justify-content: center;
}
 
.tab-content {
    border: 1px solid #ddd;
    padding: 10px;
}