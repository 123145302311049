.image-column {
    width: 40%;
    margin-left: 0px;
}

.column {
    display: flex;
    flex-direction: column;
    line-height: normal;
}

.content-wrapper {
    display: flex;
    gap: 20px;
}

.image-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1000px) {
    .image-column {
        width: 100%;
        align-items: center;
    }

    .image-wrapper {
        display: flexbox;
        flex-direction: row;

    }

    .content-wrapper {
        flex-direction: column;
        gap: 0px;
    }
}