
  .experience-card-content {
    border-radius: 24px;
    border: 2px solid #272c38;
    box-shadow: -6px -6px 12px 0px rgba(255, 255, 255, 0.25),
      6px 6px 12px 0px rgba(0, 0, 0, 0.25);
    background-color: #232732;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: #999;
    font-weight: 500;
    letter-spacing: 0.38px;
    width: 100%;
    padding: 31px 19px 14px;
  }
  
  @media (max-width: 991px) {
    .experience-card-content {
      margin-top: 40px;
    }
  }
  
  .section-header {
    align-self: center;
    display: flex;
    max-width: 100%;
    gap: 20px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.38px;
    line-height: 120%;
    justify-content: center;
    margin: 60px;
  }
  
  @media (max-width: 991px) {
    .section-header {
      flex-wrap: wrap;
    }
  }

  .experience-section {
    display: flex;
    flex-direction: column;
    padding: 30px 0 18px;
    margin-top: 124px;
  }
  
  @media (max-width: 991px) {
    .experience-section {
      margin-top: 40px;
      max-width: 100%;
    }
  }