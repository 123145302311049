.experience-row-right {
  display: flex;
  justify-content: flex-end;
}

.experience-row-left {
  display: flex;
  justify-content: flex-start;
}

.experience-row-center {
  display: flex;
  justify-content: center;
  padding: 40px;
}


.experience-card {
  /* display: flex; */
  gap: 20px;
  width: 35%;

  max-width: 100%;
  border-radius: 29px;
  background: linear-gradient(320deg, #232732 14.78%, #232732 95.73%);
  box-shadow: -15px -15px 20px 0 rgba(255, 255, 255, 0.25),
    15px 15px 20px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 1;
  /* width: 45%; */
  padding: 10px 10px 0;
  justify-self: right;
  align-self: right;
}

.experience-details {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 90%;
  margin-left: 0px;
}



.experience-card-content {
  border-radius: 24px;
  border: 2px solid #272c38;
  box-shadow: -6px -6px 12px 0px rgba(255, 255, 255, 0.25),
    6px 6px 12px 0px rgba(0, 0, 0, 0.25);
  background-color: #232732;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #999;
  font-weight: 500;
  letter-spacing: 0.38px;
  width: 100%;
  padding: 31px 19px 14px;
}

.experience-title {
  color: #fff;
  font: 600 21px/114% Poppins, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.experience-company {
  color: #666;
  margin-top: 13px;
  font: 600 17px/112% Poppins, sans-serif;
}

.experience-description {
  color: #999;
  margin-top: 18px;
  font: 600 13px/19px Poppins, sans-serif;
  margin-bottom: 40px;
  text-align: justify;
}

.experience-image-container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 23.5%;
  margin-left: 20px;

  justify-content: center;
}

.experience-image {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
  margin-left: 20px;
}

@media (max-width: 1000px) {
  .experience-card {
    flex-direction: column;
    align-items: stretch;
  }
  
  .experience-card-content {
    margin-top: 40px;
  }

  .experience-card {
    /* display: flex; */
    gap: 20px;
    width: 80%;
  
    max-width: 100%;
    border-radius: 29px;
    background: linear-gradient(320deg, #232732 14.78%, #232732 95.73%);
    box-shadow: -15px -15px 20px 0 rgba(255, 255, 255, 0.25),
      15px 15px 20px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    aspect-ratio: 1;
    /* width: 45%; */
    padding: 10px 10px 0;
    justify-self: right;
    align-self: right;
  }
}