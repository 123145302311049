.scroll-container {
  padding: 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #232732;
  flex-direction: row;
}

.content-box {
  width: 1800px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.card {
  width: 200px;
  height: 200px;
  padding: 20px;
  display: flexbox;
  align-items: center;
  justify-content: center;
  
}

.card p {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  opacity: 0.4;
}

.skill-item {
  /* margin-top: 88px; */
  display: flex;
  border-radius: 75px;
  width: 150px;
  height: 150px;
  background: linear-gradient(320deg, #232732 14.78%, #232732 95.73%);
  box-shadow: -5px -5px 5px 0 rgba(255, 255, 255, 0.30),
    10px 10px 10px 0 rgba(0, 0, 0, 0.70);

  justify-content: center;
  align-items: center;
}

.skill-img {
  height: 100px;
}

.button-div {
  padding: 20px;
}

@media (max-width: 1000px) {
  .button-div {
    display: none;
  }

  .scroll-container {
    width: 125%;
    padding: 0;
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    color: #232732;
    flex-direction: row;
  }
}