body {
  margin: 0px;
  padding: 0px;
}

.container {
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 59px 56px;
}

@media (max-width: 1000px) {
  .container {
    padding: 0 0;
  }
}

.inner-container {
  border-radius: 24px;
  box-shadow: -10px -10px 20px 0px var(--oq-white) inset,
    10px 10px 20px 0px var(--oq-black) inset;
  background-color: var(--inner-bg);
  display: flexbox;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  max-width: 1000px;
}

@media (max-width: 1000px) {
  .inner-container {
    border-radius: 0px;
    box-shadow: -0px -0px 0px 0px var(--oq-white) inset,
      0px 0px 0px 0px var(--oq-black) inset;
    background-color: var(--inner-bg);
    padding: 0 0;
    max-width: 100%;
  }
}

/* Add this to your global CSS file */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  overflow-x: hidden;
}
