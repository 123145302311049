body {
    overflow: overlay;
}

::-webkit-scrollbar {
    width: 1em;
}

::-webkit-scrollbar-track {
    background: #24272c;
}

::-webkit-scrollbar-thumb {
    background: #34383f;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #484d57;
}

:root {

    --bg: rgb(36, 39, 44);
    --inner-bg: rgb(35, 39, 50);
    --oq-white: rgba(255, 255, 255, 0.25);
    --oq-black: rgba(0, 0, 0, 0.25);
    --theam:rgb(1, 102, 121);
}