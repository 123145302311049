.proj-container {
    display: flex;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
}

@media (max-width: 1000px) {
    .proj-container {
        max-width: 100%;
        padding-left: 20px;
        margin: 40px 8px 0 0;
    }
}

