.tabs-container {
    width: 100%;
    margin: 20px auto;
    padding: 30px 0 0 0;
}
 
.tabs {
    display: flex;
    text-align: center;
    justify-content: center;
}
 
.tab-content {
    border: 1px solid #ddd;
    padding: 10px;
}

@media (max-width: 1000px) {
    .tabs-container {
        margin: 0 auto;
        padding: 0 0 0 0;
    }
     
    .tab-content {
        padding: 0;
    }
}