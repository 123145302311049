.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px;
  }
  
  .text-center {
    text-align: center;
  }
  
  .form-control {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: -5px -5px 10px 0 rgba(255, 255, 255, 0.25),
     5px 5px 10px rgba(0, 0, 0, 0.25);
  }
  
  .contact-card {
    border: 1px solid #ddd;
  }

  .input-field {
    font-family: Poppins, sans-serif;
    border-radius: 12px;
    box-shadow: 1px 1px 2px 0 rgba(44, 50, 64, 0.3),
      -1px -1px 2px 0 rgba(26, 28, 37, 0.5),
      -4px 4px 8px 0 rgba(26, 28, 37, 0.2) inset,
      4px -4px 8px 0 rgba(26, 28, 37, 0.2) inset,
      -4px -4px 8px 0 rgba(44, 50, 64, 0.9) inset,
      4px 4px 8px 0 rgba(26, 28, 37, 0.9) inset;
    background-color: #232732;
    margin-bottom: 33px;
    padding: 27px 22px;
  }
  
  input {
    width: 92%;
    padding: 10px;
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.5);
    font-size: inherit;
    font-family: inherit;
    font: 600 20px/120% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dir-column {
    flex-direction: column;
  }
  

  textarea {
    width: 92%;
    padding: 10px;
    border-radius: 4px;
    border: none;
    
    color: rgba(255, 255, 255, 0.5);
  }
  
  .btn {
    padding: 10px 20px;
    min-width: 150px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
    width: auto;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  