.div-container {
    margin-top: 50px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 20px;
}

.line-container {
    border-radius: 33px;
    background-color: #016679;
    /* 002d36 dark teal*/
    /* 016679 light teal*/
    /* 2074d3 blue*/
    /* 7733ff */
    display: flex;
    margin-top: 22px;
    width: 915px;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0 60px;
}

.divider {
    border-radius: 33px;
    background-color: #f7a21e;
    width: 291px;
    max-width: 100%;
    height: 5px;
}

.title-heading {
    color: #fff;
    text-align: left;
    letter-spacing: 0.38px;
    align-self: start;
    font: 500 34px/71% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    font-weight: 600;
}

@media (max-width: 1000px) {
    .div-container {
        padding-left: 20px;
    }

    .projects-heading {
        margin-left: 10px;
    }
}